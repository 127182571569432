
export const buildSearchUrlParams = (realEstateObject) => {
  const filterAsQueryParams = {
    objectType: realEstateObject.type,
    objectServiceType: realEstateObject.serviceType,
    cityId: realEstateObject.city ? realEstateObject.city.id : null,
    districtId: realEstateObject.district ? realEstateObject.district.id : null,
    roomsCount: realEstateObject.roomsCount,
    totalPrice: realEstateObject.totalPrice,
    currency: realEstateObject.currency,
    area: realEstateObject.area,
    kitchenArea: realEstateObject.kitchenArea,
    livingArea: realEstateObject.livingArea,
    landArea: realEstateObject.landArea,
    objectMaterial: realEstateObject.material,
    objectCondition: realEstateObject.condition,
    flooring: realEstateObject.flooring,
    squarePrice: realEstateObject.squarePrice,
    objectSubtype: realEstateObject.subtype,
    floor: realEstateObject.floor,
    landAreaPrice: realEstateObject.landAreaPrice,
  };

  Object.keys(filterAsQueryParams).forEach((key) => {
    if (filterAsQueryParams[key] === undefined || filterAsQueryParams[key] === null){
      delete filterAsQueryParams[key];
    }
  });

  return filterAsQueryParams;
};

export const getUrlWithFilterQueryParams = (realEstateObject) => {
  const filters = buildSearchUrlParams(realEstateObject);

  let queryParams = '?';

  Object.keys(filters).forEach((key) => {
    queryParams = `${queryParams}${key}=${filters[key]}&`;
  });

  return queryParams.substring(0, queryParams.length - 1);
};

export const getFilterParamsFromQuery = (query) => {
  const stringParams = query.replace('?', '').split('&');

  const params = Object.fromEntries(
    stringParams.map((param) => {
      const [key, value] = param.split('=');
      return [key, parseInt(value, 10)];
    })
  );

  return params;
};
